import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsCatalogMixin } from './mixins/cs-catalog-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

var e = window.nds;
const r = CsMixin,
  d = CsCatalogMixin,
  c = e.getSlottedChildren;

/**
 * `cs-grid` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsGrid extends r(d(PolymerElement)) {
  static get properties() {
    return {
      hidden: {
        observer: '_hiddenChanged',
        type: Boolean,
        value: !1,
      },
      type: {
        type: String,
        value: 'ordered',
      },
      cards: {
        type: Boolean,
        value: !1,
      },
      displayCardImageOnMobile: {
        type: Boolean,
        value: !1,
      },
      backgroundColor: {
        type: String,
        value: 'none',
      },
      layout: {
        type: String,
        value: '3up',
        reflectToAttribute: !0,
      },
      noMargins: {
        type: Boolean,
        value: !1,
      },
      tileType: {
        type: String,
        value: 'vertical',
      },
      tileAlign: {
        type: String,
        value: 'left',
      },
      tileTextAlign: {
        type: String,
        value: 'left',
      },
      tileTextColor: {
        type: String,
        value: 'dark',
      },
      tileColor: {
        type: String,
        value: 'light',
      },
      tileMediaShape: {
        type: String,
        value: 'rectangle',
      },
      tileScrimColor: {
        type: String,
        value: 'none',
      },
      tileScrimOpacity: {
        type: String,
        value: 'medium',
      },
      tileVerticalAlign: {
        type: String,
        value: 'vertical-bottom',
      },
      tileLinkTarget: String,
      hideMedia: {
        type: Boolean,
        value: !1,
      },
      hideEyebrow: {
        type: Boolean,
        value: !1,
      },
      hideTitle: {
        type: Boolean,
        value: !1,
      },
      hideCopy: {
        type: Boolean,
        value: !1,
      },
      hideCtas: {
        type: Boolean,
        value: !1,
      },
      hideTags: {
        type: Boolean,
        value: !1,
      },
      hideBrandStroke: {
        type: Boolean,
        value: !1,
      },
      hideByline: {
        type: Boolean,
        value: !1,
      },
      spacingTop: {
        type: String,
        value: 'medium',
      },
      spacingBottom: {
        type: String,
        value: 'medium',
      },
      twoUpOnMobile: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      animationsOnMobile: {
        type: Boolean,
        value: !1,
      },
      animateInOrder: String,
      animateOutOrder: String,
      _colNum: Number,
      _rowAnimateTimes: {
        type: Array,
        value: () => [],
      },
      _tileAnimateTimes: {
        type: Array,
        value: () => [],
      },
      _loading: {
        type: Boolean,
        value: !0,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style
      ><style include="cs-shared-styles-common-spacing"></style
      ><style include="cs-shared-styles-common-catalog"></style
      ><style>
        :host {
          display: block;
        }
        :host([background-color='dark']) {
          background-color: #2a2a2a;
        }
        :host([background-color='light']) {
          background-color: #f7f8fb;
        }
        :host([type='ordered']) .cspl-catalog__items {
          display: flex;
          flex-flow: row wrap;
        }
        :host([cards][background-color='dark']) {
          background-color: #2a2a2c;
        }
        :host .cspl-catalog__items {
          display: flex;
          flex-flow: row wrap;
        }
        :host(:not([cards])[type='organic']) .cspl-catalog__items {
          display: block;
        }
        :host([cards]) .cspl-catalog__items {
          display: flex;
          flex-flow: row wrap;
        }
        :host([tile-align='left']) .cspl-catalog__items {
          justify-content: flex-start;
        }
        :host([tile-align='center']) .cspl-catalog__items {
          justify-content: center;
        }
        :host([tile-align='right']) .cspl-catalog__items {
          justify-content: flex-end;
        }
        .cspl-catalog__items {
          margin-bottom: -16px;
        }
        :host([no-margins]) .cspl-catalog__items {
          margin-bottom: 0;
        }
        :host([cards]) .cspl-catalog__items {
          margin-bottom: 0;
        }
        .cspl-catalog__items ::slotted([slot='container']),
        .cspl-catalog__items [slot='container'] {
          display: flex;
          margin-bottom: var(--cs-gutter-mobile);
          width: 100%;
        }
        :host([two-up-on-mobile])
          .cspl-catalog__items
          ::slotted([slot='container']),
        :host([two-up-on-mobile]) .cspl-catalog__items [slot='container'] {
          width: 50%;
        }
        :host([two-up-on-mobile]) .cspl-catalog__items [slot='container'] {
          margin-bottom: calc(0.5 * var(--cs-gutter-mobile));
          padding-left: calc(0.25 * var(--cs-gutter-mobile));
          padding-right: calc(0.25 * var(--cs-gutter-mobile));
        }
        @media only screen and (min-width: 321px) {
          :host([two-up-on-mobile]) .cspl-catalog__items [slot='container'] {
            margin-bottom: var(--cs-gutter-mobile);
            padding-left: calc(0.5 * var(--cs-gutter-mobile));
            padding-right: calc(0.5 * var(--cs-gutter-mobile));
          }
        }
        @media only screen and (min-width: 768px) {
          .cspl-catalog__items ::slotted([slot='container']),
          .cspl-catalog__items [slot='container'] {
            margin-bottom: var(--cs-gutter-tablet-portrait);
            padding-left: calc(0.5 * var(--cs-gutter-tablet-portrait));
            padding-right: calc(0.5 * var(--cs-gutter-tablet-portrait));
            width: 50%;
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-catalog__items ::slotted([slot='container']),
          .cspl-catalog__items [slot='container'] {
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
          :host([no-margins])
            .cspl-catalog__items
            ::slotted([slot='container']),
          :host([no-margins]) .cspl-catalog__items [slot='container'] {
            margin-bottom: 1px;
            padding-left: 1px;
            padding-right: 1px;
          }
          :host([layout='3up'])
            .cspl-catalog__items
            ::slotted([slot='container']),
          :host([layout='3up']) .cspl-catalog__items [slot='container'] {
            width: 33.33%;
          }
          :host([layout='4up'])
            .cspl-catalog__items
            ::slotted([slot='container']),
          :host([layout='4up']) .cspl-catalog__items [slot='container'] {
            width: 25%;
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-catalog__items ::slotted([slot='container']),
          .cspl-catalog__items [slot='container'] {
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
          :host([no-margins])
            .cspl-catalog__items
            ::slotted([slot='container']),
          :host([no-margins]) .cspl-catalog__items [slot='container'] {
            margin-bottom: 1px;
            padding-left: 1px;
            padding-right: 1px;
          }
        }
        .cspl-catalog__holder {
          display: none;
        }
        .cspl-grid__gutter {
          left: -9999px;
          opacity: 0;
          position: absolute;
          width: var(--cs-gutter-mobile);
        }
        @media only screen and (min-width: 768px) {
          .cspl-grid__gutter {
            width: var(--cs-gutter-tablet-portrait);
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-grid__gutter {
            width: var(--cs-gutter-tablet-landscape);
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-grid__gutter {
            width: var(--cs-gutter-desktop);
          }
        }</style
      ><iron-ajax
        auto=""
        url="[[ filterUrl ]]"
        handle-as="text"
        on-response="_handleFilterAjaxResponse"
        on-error="_handleFilterAjaxError"
      ></iron-ajax
      ><iron-ajax id="eaCatalogAjax" handle-as="text"></iron-ajax
      ><slot name="filter"></slot>
      <div class="cspl-catalog__items" id="catalogContainer"></div>
      <div class="cspl-catalog__holder"><slot name="container"></slot></div>
      <div class="cspl-grid__gutter"></div>
      <div id="processing" class="cspl-processing-icon">
        <iron-icon icon="cs-action-24:loading"></iron-icon>
      </div>
      <slot id="loadMore" name="load-more"></slot
      ><slot id="pagination" name="pagination"></slot>
    `;
  }

  static get observers() {
    return [
      '_processCatalogItems(twoUpOnMobile,tileType,tileTextColor,tileColor,tileScrimColor,tileScrimOpacity,tileLinkTarget,tileVerticalAlign,hideMedia,hideEyebrow,hideTitle,hideCopy,hideCtas,hideTags,hideBrandStroke)',
    ];
  }
  _hiddenChanged(t) {
    !t && this._masonry && (this._masonry.layout, 1) && this._masonry.layout();
  }
  _imagesLoaded() {
    [...this._getAllDistributedTiles()].map(t => {
      t.shadowRoot
        ? this._imageLoad(t)
        : t.addEventListener('nds.catalog.tileReady', () => {
            setTimeout(this._imageLoad, 0, t);
          });
    });
  }
  _imageLoad(t) {
    const e = t.shadowRoot.querySelector('img');
    e &&
      (e.complete && this._resetLayout(e),
      (e.onload = () => {
        this._resetLayout(e);
      }));
  }
  _resetLayout(t) {
    t && this._imagesLoadedCount++,
      (this._debouncer = n.a.debounce(this._debouncer, s.c.after(10), () => {
        this._masonry.layout(),
          this.dispatchEvent(
            new CustomEvent('nds.grid.imagesloaded', {
              bubbles: !0,
              composed: !0,
              detail: {
                count: this._imagesLoadedCount,
              },
            })
          ),
          (this._imagesLoadedCount = 0);
      }));
  }
  get _catalogItems() {
    return c(this, ['container']);
  }
  _showRows(t, e) {
    [...this.shadowRoot.querySelectorAll('[slot="container"]')].forEach(
      (i, a) => {
        i.removeAttribute('style'),
          a >= t && a < e
            ? i.removeAttribute('hidden')
            : i.setAttribute('hidden', !0);
      }
    ),
      'organic' !== this.type || this.cards || this._observeCatalogContainer();
  }
  _getAllDistributedTiles() {
    return this.$.catalogContainer.querySelectorAll('[slot="tile"]');
  }
  _getAllDistributedCtas() {
    return this.$.catalogContainer.querySelectorAll('[slot="cta"]');
  }
  _processCatalogItems() {
    const t = this._getAllDistributedTiles(),
      e = this._getAllDistributedCtas();
    t.forEach((e, i) => {
      (e.twoUpOnMobile = this.twoUpOnMobile),
        (e.type = this.tileType),
        (e.textAlign = this.tileTextAlign),
        (e.textColor = this.tileTextColor),
        (e.mediaShape = this.tileMediaShape),
        (e.color = this.tileColor),
        (e.scrimColor = this.tileScrimColor),
        (e.scrimOpacity = this.tileScrimOpacity),
        (e.verticalAlign = this.tileVerticalAlign),
        (e.hideMedia = this.hideMedia),
        (e.hideEyebrow = this.hideEyebrow),
        (e.hideTitle = this.hideTitle),
        (e.hideCopy = this.hideCopy),
        (e.hideCtas = this.hideCtas),
        (e.hideCategoryTags = this.hideTags),
        (e.hideBrandStroke = this.hideBrandStroke),
        (e.hideByline = this.hideByline),
        (e.mediaSize = this.mediaSize),
        (e.context = 'cs-grid'),
        (e.card = this.cards),
        (e.showCardImage = this.displayCardImageOnMobile),
        (e.shadowColor = this.backgroundColor),
        (e.animatable = e.animatable || this.animatable),
        (e.animationThreshold =
          e.animationThreshold || this.animationThreshold),
        (e.animationExitThreshold =
          e.animationExitThreshold || this.animationExitThreshold),
        (e.animationMargin = e.animationMargin || this.animationMargin),
        (e.animationOverrides =
          e.animationOverrides || this.animationOverrides),
        (e.animationOnLoad = e.animationOnLoad || this.animationOnLoad),
        (e.animationOnEnter = e.animationOnEnter || this.animationOnEnter),
        (e.animationOnExit = e.animationOnExit || this.animationOnExit),
        (e.animationsOnMobile =
          e.animationsOnMobile || this.animationsOnMobile),
        this.tileLinkTarget && (e.linkTarget = this.tileLinkTarget),
        (this.animateInOrder || this.animateOutOrder) &&
          (this.isDesktop || (this.isMobile && this.twoUpOnMobile)) &&
          this._processOrderItems(t, i);
    }),
      e.forEach(t => {
        t.twoUpOnMobile = this.twoUpOnMobile;
      }),
      this._resetTilesAnimate(t);
  }
  _processOrderItems(t, e) {
    const i = t[e];
    this._colNum || (this._colNum = this._getGridColumns(i));
    const a = (e + 1) % this._colNum == 1,
      o = (e + 1) % this._colNum == 0 || e + 1 === t.length,
      n = {
        delayTime: '0ms',
        animateTime: this._getTileDelayTime(i),
      };
    if (a) this._rowAnimateTimes = [n];
    else {
      const t = this._rowAnimateTimes[(e % this._colNum) - 1];
      (n.delayTime = `${t.delayTime} + ${t.animateTime}`),
        this._rowAnimateTimes.push(n);
    }
    o && this._tileAnimateTimes.push(this._rowAnimateTimes);
  }
  _resetTilesAnimate(t) {
    if (!this._tileAnimateTimes || !this._tileAnimateTimes.length) return;
    const e = [];
    this._tileAnimateTimes.forEach(t => e.push(Object.assign([], t).reverse()));
    const i = 'rtl' === this.animateInOrder ? e : this._tileAnimateTimes,
      a = 'rtl' === this.animateOutOrder ? e : this._tileAnimateTimes;
    t.forEach((t, e) => {
      if (!t.updateStyles) return void (this._loading = !0);
      const o = Math.floor(e / this._colNum),
        n = e - o * this._colNum;
      this.animateInOrder &&
        i.length &&
        (t.setAttribute('animate-in-order', this.animateInOrder),
        t.updateStyles({
          '--cs-tile-order-animation-in-delay': `calc(${i[o][n].delayTime})`,
        })),
        this.animateOutOrder &&
          a.length &&
          (t.setAttribute('animate-out-order', this.animateOutOrder),
          t.updateStyles({
            '--cs-tile-order-animation-out-delay': `calc(${a[o][n].delayTime})`,
          }));
    });
  }
  _getGridColumns(t) {
    const e = t.parentNode;
    return Math.round(this.$.catalogContainer.offsetWidth / e.offsetWidth);
  }
  _getStyleValue(t, e) {
    const { animationOverrides: i = {} } = e,
      a = getComputedStyle(e).getPropertyValue(t);
    return i[t] || a || '0ms';
  }
  _getTileDelayTime(t) {
    return `${this._getStyleValue(
      '--cs-animation-delay-long',
      t
    )} + ${this._getStyleValue('--cs-animation-timing-short', t)}`;
  }
  _setupMasonry() {
    const t = this.$.catalogContainer,
      e = '[slot="container"]:not([hidden])';
    (this._masonry = new Masonry(t, {
      itemSelector: e,
      columnWidth: e,
      horizontalOrder: !0,
    })),
      this._masonry.layout.bind(this._masonry),
      this._masonry.layout(),
      (this._imagesLoadedCount = 0);
  }
  _observeCatalogContainer() {
    this._setupMasonry(), setTimeout(this._imagesLoaded);
  }
  _setup() {
    setTimeout(() => {
      this._loading &&
        ((this._loading = !1),
        this._moveTiles(),
        this._processCatalogItems(),
        'organic' !== this.type ||
          this.cards ||
          (this._catalogObserver = new o.a(
            this.$.catalogContainer,
            this._observeCatalogContainer
          )),
        this.dispatchEvent(
          new CustomEvent('nds.catalog.itemsReady', {
            bubbles: !0,
            composed: !0,
            detail: {
              items:
                this.$.catalogContainer.querySelectorAll('[slot="container"]'),
            },
          })
        ));
    });
  }
  _moveTiles() {
    const t = this._catalogItems;
    if (!this._catalogItems.length)
      return void this._adjustQdcZeroResultSlot(this._catalogItems);
    const e = document.createDocumentFragment();
    [...t].forEach(t => {
      e.appendChild(t);
    }),
      this.$.catalogContainer.appendChild(e);
  }
  constructor() {
    super(),
      (this._masonry = null),
      (this._setup = this._setup.bind(this)),
      (this._setupMasonry = this._setupMasonry.bind(this)),
      (this._imagesLoaded = this._imagesLoaded.bind(this)),
      (this._observeCatalogContainer =
        this._observeCatalogContainer.bind(this)),
      (this._imageLoad = this._imageLoad.bind(this));
  }
  connectedCallback() {
    super.connectedCallback(),
      this._setup(),
      this.addEventListener('nds.catalog.tileReady', this._setup);
  }
  disconnectedCallback() {
    this._debouncer && this._debouncer.cancel(),
      this._catalogObserver && this._catalogObserver.disconnect(),
      this.removeEventListener('nds.catalog.tileReady', this._setup);
  }
}

customElements.define('cs-grid', CsGrid);
