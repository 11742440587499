/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsLinkIconContainerMixin } from "./mixins/cs-link-icon-container-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsLinkIconContainerMixin;

/**
 * `cs-local-nav-drawer-sub-nav` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalNavDrawerSubNav extends t(GestureEventListeners(PolymerElement)) {
	static get properties() {
		return {
			toggleText: {
				type: String,
				value: "Toggle",
			},
			toggleTooltip: {
				type: String,
				value: null,
			},
			open: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "standard",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-form-select"></style>
			<style>
				:host {
					--cs-local-nav-drawer-sub-link-current-dark-color: var(--cs-theme-local-nav-drawer-sub-link-current-dark-color);
					--cs-local-nav-drawer-sub-link-current-light-color: var(--cs-theme-local-nav-drawer-sub-link-current-light-color);
					--cs-local-nav-drawer-sub-link-current-hover-dark-color: var(--cs-theme-local-nav-drawer-sub-link-current-hover-dark-color);
					--cs-local-nav-drawer-sub-link-current-hover-light-color: var(--cs-theme-local-nav-drawer-sub-link-current-hover-light-color);
				}

				:host {
					display: block;
					font-family: inherit;
					font-size: inherit;
				}

				.cspl-local-nav__sub-nav-toggle {
					align-items: center;
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					margin: 0 24px;
					padding-bottom: 17px;
					padding-top: 18px;
					text-transform: uppercase;
				}

				:host([theme="dark"]) .cspl-local-nav__sub-nav-toggle {
					border-top: solid 1px #eaeaea;
					color: var(--cs-theme-local-nav-font-color-dark);
				}

				:host([theme="light"]) .cspl-local-nav__sub-nav-toggle {
					border-top: solid 1px #585858;
					color: var(--cs-theme-local-nav-font-color-light);
				}

				:host([type="simple"]) .cspl-local-nav__sub-nav-toggle {
					border-top: none;
					font-weight: 700;
					justify-content: flex-start;
					margin: 0;
					padding-bottom: 0;
					padding-top: 0;
				}

				:host([open]) .cspl-local-nav__sub-nav-toggle,
				:host([theme="dark"]) .cspl-local-nav__sub-nav-toggle:hover,
				:host([theme="light"]) .cspl-local-nav__sub-nav-toggle:hover {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				:host(.cspl-local-nav__drawer-item--first-child) .cspl-local-nav__sub-nav-toggle {
					border-top: 0;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__sub-nav-toggle {
						margin: 0 28px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-nav__sub-nav-toggle {
						margin: 0 32px;
					}
				}

				:host([theme="dark"]) .cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-dark);
				}

				:host([theme="light"]) .cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				.cspl-dropdown__chevron:hover,
				.cspl-local-nav__sub-nav-toggle:hover .cspl-dropdown__chevron,
				:host([open]) .cspl-dropdown__chevron,
				:host([theme="dark"]):hover .cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				.cspl-local-nav__drawer-nav-sub-nav-links {
					display: block;
					margin: 0 24px;
					max-height: 0;
					overflow: hidden;
					transition: max-height var(--cs-transition-duration) var(--cs-transition-easing);
					transition-delay: 0.1s;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__drawer-nav-sub-nav-links {
						margin: 0 28px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-nav__drawer-nav-sub-nav-links {
						margin: 0 32px;
					}
				}

				:host([type="simple"]) .cspl-local-nav__drawer-nav-sub-nav-links {
					margin: 0;
				}

				.cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link) {
					align-items: center;
					display: flex;
					margin: 0 16px;
					padding-bottom: 9px;
					padding-top: 10px;
					text-decoration: none;
				}

				:host([theme="dark"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link) {
					color: var(--cs-theme-local-nav-font-color-dark);
				}

				:host([theme="dark"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:hover) {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				:host([theme="dark"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link[current]) {
					color: var(--cs-local-nav-drawer-sub-link-current-dark-color);
				}

				:host([theme="dark"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:hover[current]) {
					color: var(--cs-local-nav-drawer-sub-link-current-hover-dark-color);
				}

				:host([theme="light"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link) {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				:host([theme="light"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:hover) {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				:host([theme="light"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link[current]) {
					color: var(--cs-local-nav-drawer-sub-link-current-light-color);
				}

				:host([theme="light"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:hover[current]) {
					color: var(--cs-local-nav-drawer-sub-link-current-hover-light-color);
				}

				.cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:last-child) {
					padding-bottom: 32px;
				}

				:host([type="simple"]) .cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link:last-child) {
					padding-bottom: 0;
				}

				:host([icon]) .cspl-local-nav__drawer-nav-sub-nav-links {
					padding-left: 16px;
				}

				:host([icon-background-color]:not([icon-background-color="none"])) .cspl-local-nav__drawer-nav-sub-nav-links {
					padding-left: 30px;
				}

				@media only screen and (min-width: 768px) {
					:host([icon]) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 22px;
					}

					:host([icon-background-color]:not([icon-background-color="none"])) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 36px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link) {
						margin: 0 20px;
					}

					:host([icon]) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 18px;
					}

					:host([icon-background-color]:not([icon-background-color="none"])) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 32px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-link) {
						margin: 0 24px;
					}

					:host([icon]) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 14px;
					}

					:host([icon-background-color]:not([icon-background-color="none"])) .cspl-local-nav__drawer-nav-sub-nav-links {
						padding-left: 28px;
					}
				}

				.cspl-local-nav__drawer-nav-sub-nav-links ::slotted(.cspl-local-nav__drawer-sub-tile) {
					margin: 20px;
				}

				:host([type="standard"]) .cspl-local-nav__toggle-text {
					flex-grow: 1;
				}
			</style>
			<div class="cspl-local-nav__sub-nav-toggle" on-tap="_toggleSubNav" title="[[ toggleTooltip ]]">
				<template is="dom-if" if="[[ _hasIcon(icon) ]]">
					<cs-icon-container id="icon" icon="[[ icon ]]" color="[[ iconColor ]]" background-color="[[ iconBackgroundColor ]]" hovered="[[ hovered ]]">
					</cs-icon-container>
				</template>
				<span class="cspl-local-nav__toggle-text">[[ toggleText ]]</span>
				<div class="cspl-dropdown__chevron"></div>
			</div>
			<div id="links" class="cspl-local-nav__drawer-nav-sub-nav-links">
				<slot name="local-nav-drawer-sub-link"></slot>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(), (this._toggleSubNav = this._toggleSubNav.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_toggleSubNav() {
		this.open = !this.open;
		const e = this.open ? this.$.links.scrollHeight : 0;
		requestAnimationFrame(() => {
			this.$.links.style.maxHeight = `${e}px`;
		});
	}
	_closeSubNav() {
		this.open = !1;
	}
	_openSubNav() {
		this.open = !0;
	}
	connectedCallback() {
		super.connectedCallback(), this.addEventListener("cs-current-link-change", this._toggleSubNav); // jshint ignore:line
	}
}

customElements.define("cs-local-nav-drawer-sub-nav", CsLocalNavDrawerSubNav);
