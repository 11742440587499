/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsDeviceMixin } from "./mixins/cs-device-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsDeviceMixin;

/**
 * `cs-local-nav-shelf-sub-nav` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalNavShelfSubNav extends t(a(GestureEventListeners(PolymerElement))) {
	static get properties() {
		return {
			toggleText: String,
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			open: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			mini: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			detached: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "wide",
				reflectToAttribute: !0,
			},
			_linksImages: {
				type: Array,
				value() {
					return [];
				},
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-form-select"></style>
			<style>
				:host {
					display: block;
				}

				.cspl-local-nav__sub-nav-toggle-text {
					align-items: center;
					color: var(--cs-theme-local-nav-font-color-dark);
					cursor: pointer;
					display: flex;
					font-family: var(--cs-theme-local-nav-shelf-header-font-family);
					font-size: var(--cs-theme-local-nav-shelf-header-font-size-mobile);
					height: 100%;
					justify-content: space-between;
					letter-spacing: var(--cs-theme-local-nav-shelf-header-letter-spacing-mobile);
					text-transform: uppercase;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__sub-nav-toggle-text {
						font-size: var(--cs-theme-local-nav-shelf-header-font-size-tablet-landscape);
						letter-spacing: var(--cs-theme-local-nav-shelf-header-letter-spacing-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-nav__sub-nav-toggle-text {
						font-size: var(--cs-theme-local-nav-shelf-header-font-size-desktop);
						letter-spacing: var(--cs-theme-local-nav-shelf-header-letter-spacing-desktop);
					}
				}

				:host([theme="light"]) .cspl-local-nav__sub-nav-toggle-text {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				.cspl-local-nav__sub-nav-toggle-text:hover,
				:host([theme="light"]) .cspl-local-nav__sub-nav-toggle-text:hover {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				:host([open]) .cspl-local-nav__sub-nav-toggle-text {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				.cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-dark);
				}

				:host([theme="light"]) .cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				.cspl-dropdown__chevron:hover,
				.cspl-local-nav__sub-nav-toggle-text:hover .cspl-dropdown__chevron,
				:host([open]) .cspl-dropdown__chevron {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				.cspl-dropdown__chevron {
					height: 0.4em;
					width: 0.8em;
				}

				.cspl-dropdown__chevron::after,
				.cspl-dropdown__chevron::before {
					border-radius: 0.5em;
					border-top: 0.1em solid currentColor;
					height: 0.1em;
					width: 0.5em;
				}

				.cspl-local-nav__shelf-nav-sub-nav-links-wrapper {
					display: flex;
					left: 0;
					padding-top: 8px;
					position: absolute;
					top: 100%;
					transform: scaleY(0);
					transform-origin: top;
					transition: transform var(--cs-transition-duration) cubic-bezier(0.5, 0.25, 0.015, 1);
					transition-delay: 0.1s;
					width: 100%;
					z-index: -1;
				}

				:host([open]) .cspl-local-nav__shelf-nav-sub-nav-links-wrapper {
					transform: scaleY(1);
					transition-delay: 0s;
				}

				:host([type="narrow"]) .cspl-local-nav__shelf-nav-sub-nav-links-wrapper,
				:host([type="simple"]) .cspl-local-nav__shelf-nav-sub-nav-links-wrapper {
					left: auto;
					width: auto;
				}

				.cspl-local-nav__shelf-nav-sub-nav-links-container {
					background-color: rgba(255, 255, 255, 0.95);
					border-radius: 4px;
					display: flex;
					justify-content: center;
					overflow: hidden;
					padding-bottom: 40px;
					padding-top: 40px;
					text-transform: none;
					width: 100%;
				}

				:host([type="narrow"]) .cspl-local-nav__shelf-nav-sub-nav-links-container,
				:host([type="simple"]) .cspl-local-nav__shelf-nav-sub-nav-links-container {
					margin-left: -35px;
					padding-left: 35px;
					width: auto;
				}

				:host([type="simple"]) .cspl-local-nav__shelf-nav-sub-nav-links-container {
					padding-bottom: 10px;
					padding-top: 0;
				}

				:host([theme="light"]) .cspl-local-nav__shelf-nav-sub-nav-links-container {
					background-color: rgba(0, 0, 0, 0.9);
					border-color: #222;
					color: var(--cs-light-color);
				}

				.cspl-local-nav__shelf-nav-sub-nav-links-container ::slotted(cs-local-nav-shelf-sub-nav-section) {
					flex-basis: 20%;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__shelf-nav-sub-nav-links-container ::slotted(cs-local-nav-shelf-sub-nav-section) {
						flex-basis: 15%;
					}
				}

				:host([type="narrow"]) .cspl-local-nav__shelf-nav-sub-nav-links-container ::slotted(cs-local-nav-shelf-sub-nav-section),
				:host([type="simple"]) .cspl-local-nav__shelf-nav-sub-nav-links-container ::slotted(cs-local-nav-shelf-sub-nav-section) {
					flex-basis: unset;
					margin-right: 30px;
				}

				:host([type="narrow"]) .cspl-local-nav__shelf-nav-links-column,
				:host([type="simple"]) .cspl-local-nav__shelf-nav-links-column {
					flex-basis: unset;
				}

				.cspl-local-nav__shelf-nav-links-column-animation {
					align-items: center;
					display: none;
					flex-basis: 0%;
					justify-content: flex-start;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-nav__shelf-nav-links-column-animation {
						flex-basis: 25%;
					}
				}

				:host([type="wide"]) .cspl-local-nav__shelf-nav-links-column-animation {
					display: flex;
				}

				.cspl-local-nav__shelf-nav-links-image {
					height: 234px;
					opacity: 0;
					position: absolute;
					transition: 0.8s cubic-bezier(0.3, 0, 0.31, 1);
					transition-property: transform, opacity;
					width: 416px;
				}

				.cspl-local-nav__shelf-nav-links-image.selected {
					opacity: 1;
				}
			</style>
			<div class="cspl-local-nav__sub-nav-toggle-text">
				{{ toggleText }}
				<div class="cspl-dropdown__chevron"></div>
			</div>
			<div class="cspl-local-nav__shelf-nav-sub-nav-links-wrapper">
				<div class="cspl-local-nav__shelf-nav-sub-nav-links-container">
					<slot name="sub-nav-section"></slot>
					<div class="cspl-local-nav__shelf-nav-links-column-animation">
						<template is="dom-repeat" items="[[ _linksImages ]]" as="image">
							<img class="cspl-local-nav__shelf-nav-links-image" src$="[[ image ]]" />
						</template>
					</div>
				</div>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(),
			(this._onShelfSubNavSectionLinkImage = this._onShelfSubNavSectionLinkImage.bind(this)),
			(this._handleImageDisplay = this._handleImageDisplay.bind(this)),
			(this._onTap = this._onTap.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_toggleSubNav() {
		(this.open = !this.open),
			this.dispatchEvent(
				new CustomEvent("nds.shelfSubNav.open", {
					bubbles: !0,
					composed: !0,
					detail: {
						open: this.open,
					},
				})
			),
			this._bindOpenStateToChildren(); // jshint ignore:line
	}
	_bindOpenStateToChildren() {
		this._sections.forEach((e) => {
			e.open = this.open;
		});
	}
	_prepareChildren() {
		setTimeout(() => {
			(this._sections = this.querySelectorAll("cs-local-nav-shelf-sub-nav-section")),
				this._sections.forEach((e) => {
					(e.theme = this.theme), (e.simple = "simple" === this.type); // jshint ignore:line
				}); // jshint ignore:line
		});
	}
	_onShelfSubNavSectionLinkImage(e) {
		e.detail && this.push("_linksImages", e.detail); // jshint ignore:line
	}
	_handleImageDisplay(e) {
		const t = this.shadowRoot.querySelectorAll(".cspl-local-nav__shelf-nav-links-image");
		t.forEach((t) => {
			t.src && e.detail && t.src.includes(e.detail) ? t.classList.add("selected") : t.classList.remove("selected"); // jshint ignore:line
		});
	}
	_getAncestorByName(e, t) {
		for (let a = e; a.parentElement; ) {
			if (a.nodeName === t) return a;
			a = a.parentElement;
		}
		return null;
	}
	_onTap(e) {
		const t = this._getAncestorByName(e.target, this.nodeName);
		this.open && (!t || t !== this) && (this.open = !1); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(),
			this._prepareChildren(),
			window.addEventListener("nds.shelfSubNavSectionLink.image", this._onShelfSubNavSectionLinkImage),
			window.addEventListener("nds.shelfSubNavSectionLink.hover", this._handleImageDisplay),
			window.addEventListener("tap", this._onTap),
			this.isTouch
				? this.addEventListener("click", this._toggleSubNav)
				: (this.addEventListener("mouseenter", this._toggleSubNav), this.addEventListener("mouseleave", this._toggleSubNav)); // jshint ignore:line
	}
	disconnectedCallback() {
		super.disconnectedCallback(),
			window.removeEventListener("nds.shelfSubNavSectionLink.image", this._onShelfSubNavSectionLinkImage),
			window.removeEventListener("nds.shelfSubNavSectionLink.hover", this._handleImageDisplay),
			window.removeEventListener("tap", this._onTap); // jshint ignore:line
	}
}

customElements.define("cs-local-nav-shelf-sub-nav", CsLocalNavShelfSubNav);
